import i18n from "i18next";
// import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
// import LocalStorageBackend from "i18next-localstorage-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
	.use(HttpBackend)
	.use(LanguageDetector)
	// .use(LocalStorageBackend)
	.use(initReactI18next)
	.init({
		fallbackLng: "en",
		detection: {
			order: ["cookie", "localStorage", "navigator"],
			caches: ["cookie"],
		},
		/* backend: {
			backends: [LocalStorageBackend, HttpBackend],
			backendOptions: [
				{
					expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
				},
			],
		}, */
		saveMissing: true, // Optional: to save missing translations
		interpolation: {
			escapeValue: false, // Not needed for React
		},
		react: {
			useSuspense: false, // Disable suspense mode
		},
		whitelist: ["en", "az", "ru"], // Whitelist of supported languages
	});

export default i18n;
